// eslint-disable-next-line import/no-extraneous-dependencies
import { Server } from 'miragejs';

export default function ({ environment = 'development' } = {}) {
  return new Server({
    environment,

    routes() {
      this.urlPrefix = process.env.VUE_APP_API_URL;

      this.passthrough();
    },
  });
}
